import axios from 'axios'
import{ElMessageBox}from 'element-plus'
declare module 'axios' {
  interface AxiosResponse {
    message?: string
    success?: boolean
  }
}


axios.defaults.withCredentials = true //默认携带cookie请求

//axios.defaults.baseURL = "http://localhost:3001" //生产环境需要修改,优先于代理
//这里默认自带了token，如果get方式传输，需要改
axios.interceptors.request.use((data:any) => {
  
  if (localStorage.getItem('token')) data.headers.token = localStorage.getItem('token')

  return data
})

//响应拦截
axios.interceptors.response.use((res: any) => {
  // 检查响应头部字段
  // if (!res.headers['Access-Control-Allow-Credentials']) {
  //   res.headers['Access-Control-Allow-Credentials'] = 'true'
  // }
  // console.log(res.headers)
  //返回成功，并植入token
  //  if(res.data?.data?.token&&res.data?.data?.token.length>10){
  //     // console.log(res.data.data.token,'inter')
  //     // localStorage.setItem('token',res.data.data.token)
  //  }
  return res.data //过滤到纯后端数据
}, err => {
  // console.log(err.response.data,'interceptor')
  //     const Reg=//
  //   if(err.response.data.message)
  console.log(err) //后端断开或者返回400以上错误才走这里
  ElMessageBox.alert(err.response?.data.message,'信息沿着错误')
  return err.response?.data// 净化到纯响应数据,错误后任然后返回值
})
export default axios