export interface Menu {
    menu: string[]
    sub: {
      subMenu: string[]
    }
  }

  export type Hr={
    position:string
    num:string
    location:string
    education:string
    responsibility:string[]
    opration:string
  }




  export enum RU{
    '首页'='main01',
    '公司概况'="overview",
    "企业文化"='culture',
    "企业资质"='qualification',
    "危险品许可证"='permittion',
    "通用化学品"="commodity",
    "功能化学品"='functional',
    "产品说明"='description',
    "生产装备"="proDevice",
    "研发装备"="research",
    "产学研合作"="cooperation",
    "nono"='nothing',
    "招聘岗位"="hr",
    "联系方式"='info',
    "给我留言"='messageboard',
    "公司位置"='location'
  }

  export enum Position{
    "研发助理"=0,
    "现金会计"=1,
    "电工"=2,
    "环保内勤"=3
  }

  export type Resume={
    position:string
    name:string
    gender:string 
    birth:Date
    nation:string
    Political:string
    education:string
    native:string
    graduation:string
    major:string
    phone:number|null
    Email:string
    address:string
    salary:number
    introduction:string
    verify:string

  }