import { createApp } from 'vue'
import App from './App.vue'
import axios from './axios/index'
import { RouteRecordRaw } from 'vue-router'
import { router } from './router/index'
import 'element-plus/dist/index.css'
import '../public/icon.css'
import 'animate.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import nothing from './views/aboutNothing.vue'
// import 'element-plus/theme-chalk/dark/css-vars.css' #全局配置暗黑模式
import {addRoutes, getRoutes } from './function/getroutes'
// console.log('hahah')
// localStorage.clear()

// const getR = async () => {
//    let routes:Array<RouteRecordRaw>
//    if(!localStorage.getItem('routes')){
//     routes = await getRoutes()
//     localStorage.setItem('routes', JSON.stringify(routes))
//     addRoutes(router)
//    }else{
//     localStorage.removeItem('routes')
//     getR()
//    }
// }
const routes=await getRoutes()
localStorage.setItem('routes',JSON.stringify(routes))
addRoutes(routes,router)

const app = createApp(App)
// app.config.globalProperties.$axios = axios

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    // console.log(key,component)
    app.component(key, component)
}


app.use(router).mount('#app')
