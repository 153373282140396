import axios from '../axios'
import { RouteRecordRaw, useRouter, Router } from 'vue-router'




export const getRoutes = async (roll?: string) => { //参数默认visitor，当为admin时，增加别的路由
    const res = await axios.post('/local/router/getRoutes', { roll: roll })
    return res.data
}

export interface Routes {
    path: string;
    name: string;
    component: string 
    meta?: Meta;
    children?: Child[]
    props?: Props
}

interface Child {
    path: string;
    name: string;
    component: string;
    meta?: Meta;
    props?: Props;
}

interface Props {
    imgs?: string[];
    width?: number[];
}

interface Meta {
    transitionIn: string;
    transitionOut: string;
}


export const addRoutes =(routes:Array<RouteRecordRaw>,router:Router, parent = '') => {
    routes.map(
        (item: RouteRecordRaw) => {
            router.addRoute(parent, {
                path: item.path,
                name: item.name,
                meta: item.meta,
                props: item.props!,
                component: () => import(`../views/${item.component}.vue`),
                // redirect:item.redirect  //不能添加
            })
            if (!item.component && item.children?.length) {
                router.addRoute({ path: item.path, redirect: item.children![0].path,children:item.children})
                router.removeRoute(item.name!)
            }
            // item.path=='/' && router.addRoute({
            //     path:'/',
            //     redirect:'/main'
                
            // })
            // child.map(ch=>console.log(ch))
           item.children && addRoutes(item.children,router,item.path) //需要parent
        }
    )
    // router.removeRoute('/about')
} 
