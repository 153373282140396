import { ElMessage,} from 'element-plus'
import { router } from '../router/index'
import {RU} from '../types'

export const handleSelect = (key: string, keyPath: string[] ,event:Event) => {
  const routes = JSON.parse(localStorage.getItem('routes')!)
  //  console.log(routes,key,keyPath)
  if (key == "99")
    window.location.href = 'https://jinjiumo.tendfs.com'
  // console.log(event)
  // const submenuLen = keyPath[1] ? keyPath[1].length - 1 : undefined
  // const subMenuNum = keyPath[1] ? Number(keyPath[1].substring(submenuLen!)) - 1 : undefined
  // const mainMenuNum = Number(key.substring(0, 1))
  // console.log(submenuLen,subMenuNum,mainMenuNum)
  // //console.log(mainMenuNum,subMenuNum) //获取了二级,一级菜单编号
  // // console.log(routes[mainMenuNum].children?routes[mainMenuNum].children![subMenuNum!]:routes[mainMenuNum],'---',routes[mainMenuNum])
  // if (routes[mainMenuNum] && routes[mainMenuNum].children) {
  //   router.push(routes[mainMenuNum].children && routes[mainMenuNum].children![subMenuNum!] ? routes[mainMenuNum].children![subMenuNum!] : routes[mainMenuNum])
  // } else if (routes[mainMenuNum] && !routes[mainMenuNum].children) {
  //   router.push(routes[mainMenuNum])
  // }
  // else {
  //   router.push('/nothing')
  //   ElMessage('开发中')
  // }
}
type ru=typeof RU
export const goRoute=(e:Event)=>{
   let html=e.target as HTMLElement
//    console.log(RU['公司概况'])
   let value=html.textContent as keyof ru
   RU[value]?router.push({name:RU[value]}):router.push('/nothing')

}
