import { ElMessage, ElMessageBox } from 'element-plus'
import type { Action } from 'element-plus'

export const openVant = () => {
    ElMessageBox.alert('跳转至手机浏览', '', {
      // if you want to disable its autofocus
      // autofocus: false,
      confirmButtonText: '好的',
      callback: (action: Action) => {
        if (action == "confirm") {
          window.location.href = 'http://jinjiumo.tendfs.com'
        }
  
        // console.log(action)
        // ElMessage({
        //   type:"success",
        //   message: `action: ${action}`,
        // })
      },
    })
  }