import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import {h,render,Teleport,createVNode}from 'vue'
import trans from '../components/transition.vue'
interface Trans extends InstanceType<typeof trans>{
  props:{
    color:string
    show:boolean
  }
}
// interface Trans {
//    props:{
//     imgs:string
//     width:number
//    }
// }



declare module 'vue-router' {
  interface RouteMeta {
    transitionIn: string
    transitionOut: string
  }
}
export const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'main',
  //   component: main,
  //   meta: {
  //     transitionIn: 'animate__animated animate__fadeInUp comsec',
  //     transitionOut: 'animate__animated animate__fadeOutUp'
  //   }
  // },
// {
//     path: '/about',
//     name: 'about',
//     // component:aboutView,
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
//     children: [
//       {
//         path: '',
//         name: 'overview',
//         component: overview,
//         meta: {
//           transitionIn: 'animate__animated animate__fadeInUp comsec',
//           transitionOut: 'animate__animated animate__fadeOutUp'
//         }
//       },
//       {
//         path: '/about/culture',
//         name: 'culture',
//         component: culture,
//         meta: {
//           transitionIn: 'animate__animated animate__fadeInUp comsec',
//           transitionOut: 'animate__animated animate__fadeOutUp gosec'
//         },
//         props: {
//           imgs: ['hahah'],
//           width: [9090]
//         }
//       },
//       {
//         path: '/about/nothing',
//         name: 'nothing',
//         component: aboutView,
//         // meta: {
//         //   transitionIn: 'animate__animated animate__fadeIn',
//         //   transitionOut: 'animate__animated animate__fadeOutUp'
//         // }
//       },
//     ]
// }  
]



export const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: (to, from, savedPositon) => {
    //console.log(from)
    // console.log(savedPositon,'to--->',to)
    // return savedPositon?savedPositon:{top:99999}
  },
  routes
})


let Vnode=createVNode(trans,{show:true})
render(Vnode,document.body)
//需要实现--切换过程中挂载展示，切换完成后，消失 
//router中引入，若挂载到#app相关位置，会破坏原有dom树

router.beforeEach((to, from, next) => {
  // (Vnode.component!.exposed!.show.value)=true
  Vnode.component?.exposed!.animate01()
  new Promise((res, rej) => {
    setTimeout(() => {
      next()
    }, 500)
  })

})
// console.log(router,'router!!',document)


router.afterEach((to, next) => {
  Vnode.component?.exposed!.animate02()
// (Vnode.component!.exposed!.show.value)=false

  //中间件启动期间，props尚未形成
  // let trans=to.matched.map(item=>{
  //   let culture=item.components?.default as Trans
  //       culture.props.width=[90]
  // })

})